@import url('https://fonts.googleapis.com/css?family=Questrial');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

$cleareye-blue: #3e6282;
$split-complement-light-green: #ebead4;
$cleareye-blue-darker: #2b445a;
$cleareye-blue-lighter: #c7d7e4;
$header-font-family: 'Questrial', sans-serif;
$body-font-family: 'Open Sans', sans-serif;

$primary: $cleareye-blue;
$light: $cleareye-blue-lighter;
$dark: $cleareye-blue-darker;

section.blurb {
  background-color: $cleareye-blue-lighter;
  text-align: center;  
}

h1, h2, h3, h4 {
  font-family: $header-font-family;
}

.header6 {
  font-family: $body-font-family;
  font-weight: bold;
  font-size: 1rem;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

p {
  font-family: $body-font-family;
}

section.body-section {
  padding: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  p {
    font-size: 1rem;
  }
  h3, h4{
    color: $cleareye-blue;
  }
}
section.alternate-section {
  background-color: $split-complement-light-green;
}

a:link {  
  color: #4E7CA5;
}

a:hover {
  text-decoration: underline;
  color: $cleareye-blue-lighter;
}

a:visited {
  color: #4E7CA5;
}

#navbar-banner {
  font-family: $header-font-family;
  font-size: 2rem;
  color: $cleareye-blue;
}

@import "../node_modules/bootstrap/scss/bootstrap";